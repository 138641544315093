.login-container {
  background-color: #eef0f1;

  @media screen and(max-width: $tablet) {
    background-color: white;
    height: auto;
  }

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  box-shadow: 3px 3px 23px -12px rgba(0, 0, 0, 0.75);
  background-color: white;
  padding: 40px 100px 80px 100px;
  border-radius: 5px;

  @media screen and(max-width: $tablet) {
    padding: 0px;
    box-shadow: none;
  }
}

.register-container {
  padding: 40px 50px 80px 50px;
  max-width: 532px;
}

.login-input {
  border: none;
  font-size: 18px;
  padding: 10px 0 10px 5px;
  width: 100%;
  font-weight: 200;
  border-bottom: 1px solid #979797;

  &:focus {
    outline: none;
  }

  &.error {
    border-bottom: 1px solid rgb(255, 75, 75);
    transition: border-bottom 1s;
  }
}

.jrc-logo {
  width: 250px;
}

.login-ball {
  height: 83px;
  width: 83px;
  border-radius: 50%;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background-color: #e2e2e2;

  &.green {
    background-color: #a7ffc2;
    transition: background-color 1s;
  }
}
