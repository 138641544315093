.contact-info {
  max-width: 300px;
}

.profile-picture {
  width: 250px;
  height: 250px;
  object-fit: contain;
}

.sticky-inner-wrapper {
  z-index: 15;
}

.bold {
  font-weight: bold;
}

#exchange {
  margin-top: 12px;
}

.image-cropper {
  margin-top: 1em;
}
