.wiki ul {
  list-style-type: circle;
  & li {
    margin-left: 30px;
    & a {
      margin-right: 5px;
    }
    & p {
      color: grey;
      display: inline-block;
    }
  }
}
