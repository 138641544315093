.mobile-menu {
  padding: 5px 20px 3px 20px;
  background-color: #f7f9fa;
  position: fixed;
  top: 0;
  z-index: 50;
  width: 100%;
}

.mobile-menu-overlay {
  z-index: 50;
  .item {
    padding: 10px;

    &.active {
      a {
        color: $primary;
      }
    }

    a {
      color: black;
    }
  }
}

.left-menu {
  width: 251px;
  background-color: #f7f9fa;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  .logo {
    width: 50px;
  }

  nav {
    margin-top: 30px;

    .item {
      margin: 18px 0 0 42px;

      a {
        color: #5e5e5e;
      }

      &.active {
        a {
          color: #cb2026;
        }
      }
    }
  }

  &__logo {
    margin-left: 42px;
    margin-top: 40px;
  }

  .logout:hover {
    cursor: pointer;
    color: grey;
  }
}

main {
  margin-top: 15px;
  margin-left: 290px;
  margin-right: 30px;

  @media screen and(max-width: $tablet) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 40px;
  }
}

.user-card {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  img {
    width: 58px;
    height: 58px;
  }

  a {
    color: #333333;

    &:hover {
      color: #919191;
    }
  }
}

.small-profile-image {
  img {
    width: 40px;
    height: 40px;
  }
}

// Loading spinner
.spinner {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgb(134, 134, 134);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

.button.is-black:hover {
  opacity: 0.8;
}
