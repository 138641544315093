@import '../../node_modules/bulma/sass/utilities/initial-variables';
$primary: #cb2026;
$success: #a7ffc2;
@import '../../node_modules/bulma/bulma';

$hamburger-padding-x: 0px !default;
$hamburger-padding-y: 0px !default;
@import '../../node_modules/hamburgers/_sass/hamburgers/hamburgers';
@import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg';
@import '../../node_modules/react-s-alert/dist/s-alert-default.css';
@import '../../node_modules/react-s-alert/dist/s-alert-css-effects/stackslide.css';
@import '../../node_modules/bulma-tooltip/dist/bulma-tooltip';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import 'layout';
@import 'home';
@import 'ansatte';
@import 'login';
@import 'commentBox';
@import 'styleguide';
@import 'wiki';

// * {
//   outline: 1px solid red !important;
// }

html,
#root {
  height: 100%;
}

li > .public-DraftStyleDefault-block {
  margin: 0;
}

.black {
  color: black;
}

.grey {
  color: #5e5e5e;
}

.no-outline:focus {
  outline: 0;
}

// Make sure that the next container div is 100% height
body {
  font-family: Roboto;
  color: black;
  height: 100% !important;

  > :first-child {
    height: 100%;
  }
}

.first-order-mobile {
  @media (max-width: $tablet) {
    order: -1;
  }
}

#__next {
  height: 100%;

  > div {
    height: 100%;
  }
}

.is-fullheight {
  height: 100%;
}

.columns {
  margin: 0;
}

@media screen and (min-width: 768px) {
  .columns {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -0.75rem;
  }
}

// Show overflow in modals. Allows dropboxes to float over edges
.modal-card-body {
  overflow: auto;
}

// Use this instead of <br />
.border {
  border-bottom: 0.5px solid #cbcbcb;

  &.fix-height {
    height: 1px;
  }
}

// Round image
img {
  &.round {
    border-radius: 50%;
  }
}

// Flexbox helpers
.flex-column {
  flex-direction: column;
}

.flex-column-mobile {
  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }
}

.flex-center {
  justify-content: center;
}

.table-row {
  display: table-row;
  text-decoration: none;
  color: #050708;
}

.table-row > td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.table tr > td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.search-bar {
  max-width: 180px;
  &:focus {
    max-width: 280px;
    transition: max-width 0.5s;
  }
}

.table > thead > tr > th {
  border-width: 0 0 1px;
  color: #5e5e5e;
  font-weight: 400;
}

.table {
  tr,
  .table-row {
    cursor: pointer;
    color: #363636;
    &:hover {
      color: $primary;
    }
  }
}

.background-white {
  background-color: white;
}

.member-picture {
  width: 150px;
  height: 150x;
}

.image-container {
  position: relative;
}

.image-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 250px;
  cursor: pointer;
}

/*
  Adds margin and padding helpers.

  Examples:

  m-t-10 = margin-top: 10px;
  p-b-20 = padding-bottom: 20px;
*/
$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100,
  150,
  200,
  250
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

$content-blockquote-background-color: $background !default;
$content-blockquote-border-left: 5px solid $border !default;
$content-blockquote-padding: 1.25em 1.5em !default;

.blockquote {
  background-color: $content-blockquote-background-color;
  border-left: $content-blockquote-border-left;
  padding: $content-blockquote-padding;
}

.thematicBreak {
  background-color: $primary;
}

// Text helpers

.selected-member-list {
  height: 400px;
  overflow-y: auto;
}

.member-list-suggest {
  position: relative;
}

.unselected-member-list {
  background-color: #ffffff;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  position: absolute;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-radius: 0 0 5px 5px;
}

.member-suggestion {
  border-bottom: 1px #dbdbdb solid;
  padding: 6px 0 3px 6px;
  cursor: pointer;

  &:hover {
    color: #cb2026;
  }
}

.event-profile-picture {
  height: 110px;
  width: 110px;
}

.sticky-inner-wrapper::after {
  box-shadow: rgba(17, 17, 17, 0.06) 0px 4px 8px -3px inset;
  height: 5px;
  z-index: 500;
  position: absolute;
  bottom: -5px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.flex-align-center {
  align-items: center;
}

.button-icon-center {
  padding-right: 5;
}

.info-text {
  font-size: 11px;
}

.project-button {
  margin: 0.5rem;
}

.button.is-primary.mark-button {
  background-color: #5e5e5e;
}

.react-datepicker__day--selected {
  background-color: $primary !important;
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: $primary;
}
