.guide-section {
  margin-top: 30px;
  margin-bottom: 20px;

  .is-size-4 {
    margin-bottom: 10px;
  }

  button {
    margin: 20px 20px 0px 0px;
  }
}

.color-box {
  height: 75px;
  border-radius: 10px;
  padding: 0.8em;
  width: 80px;
}

.color-span {
  display: block;
  color: #fff;
  line-height: 1.2;
  font-size: 14px;
  margin-left: 10px;
}

.color-boxes {
  display: flex;
  justify-content: space-evenly;
  align-content: space-around;
}

.logos {
  display: flex;
  flex-direction: column;
}

.styleguide .section {
  padding: 0rem 1.5rem 3rem 0rem !important;
}

.styleguide .section:first-child {
  padding-bottom: 0px !important;
}

.styleguide img {
  background: #9e9e9e00;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin-right: 20px;
  align-self: flex-start;
  max-width: 226px;
  max-height: 200px;
}

.logo-container {
  display: flex;
  flex-wrap: wrap;
}

.logo-description {
  width: 100%;
  max-width: 400px;
}

.template-name {
  margin-top: 23px;
  top: 26px;
  margin-right: 10px;
  position: relative;
  width: 150px;
}

.box-shadow {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box-shadow::after {
  content: '';
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.box-shadow:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
}

.box-shadow:hover::after {
  opacity: 1;
}

.template-container {
  display: flex;
  justify-content: space-around;

  .card {
    width: 100%;
    margin: 10px 10px 30px 0px;
  }
  iframe {
    width: 100%;
    height: 600px;
  }
}
