.btn-flat {
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  padding: 10px;
  font-size: 1.1em;
  &:hover {
    opacity: 1;
    cursor: pointer;
    color: grey;
  }
}

.text-align-right {
  text-align: right;
}

.CommentBox {
  white-space: nowrap;
  .textbox-footer {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 10px;
    position: relative;
    .button {
      margin-right: 10px;
    }
  }
  .CommentList {
    display: inline-block;
    margin-right: 10px;
    max-width: 80%;
    position: relative;
    vertical-align: top;
    white-space: normal;
    width: 100%;
  }
}

.CommentList {
  padding: 0;

  .Comment {
    margin-top: 0;
    margin-bottom: 4px;
    background-color: #f7f9fa;
    border-radius: 2px;
    color: #343434;
    font-size: 14px;
    line-height: 1.25;
    min-height: 28px;
    padding: 12px 20px 40px;
    position: relative;

    a {
      color: #5e5e5e;

      &:hover {
        color: #919191;
      }
    }
    .Comment-body {
      word-wrap: break-word;
    }
    .text-align-right {
      font-size: 10px;
    }
  }
  .loading-button {
    max-width: fit-content;
    height: 40px;
    margin: 20px 0px;
    position: center;
    padding-left: 18em;
    padding-right: 16.5em;
  }
}

.CommentForm {
  background-color: #f7f9fa;
  border-radius: 2px;
  color: #343434;
  font-size: 14px;
  line-height: 1.25;
  min-height: 28px;
  padding: 14px 20px 10px;
  position: relative;
  p {
    color: #5e5e5e;
    font-size: 12px;
  }
  .Comment-avatar {
    border-radius: 48px;
    img {
      border-radius: 48px;
    }
  }
  .Comment-body {
    p {
      margin: 0;
      padding: 6px 0;
    }
  }
  .columns {
    margin-bottom: 0;
  }
  a {
    color: #5e5e5e;

    &:hover {
      color: #919191;
    }
  }
}
